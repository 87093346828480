import { Accordion, AccordionDetails, AccordionSummary, Grid, TableSortLabel, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { VictoryChart, VictoryTheme, VictoryAxis, VictoryBar, VictoryZoomContainer, VictoryTooltip } from 'victory';
import { chartTheme } from '../../utlis/chartsStyle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { dynamicSort, HomePageAction, HomePageState } from './homePageViewModel';
import { userId } from '../../utlis/security';


export function ReadingsAll(props: { readings: any[], config: any, state: HomePageState, dispatch: React.Dispatch<HomePageAction> }) {
  const convertDate = (item: any) => {
    if (item != "Invalid Date") {
      return item.toISOString()
    }
    return (new Date).toISOString()
  }
  return <Grid container justify="center" style={{ marginBottom: 40 }}>
    <Grid xs={6}>
      <Typography variant="h2" style={{ marginTop: "3rem" }}>
        Letture
      </Typography>

    </Grid>
    <Grid xs={6} item style={{ paddingTop: 38 }}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        style={{ width: '95%' }}
        format="DD/MM/yyyy"
        margin="normal"
        id="date-picker-inline"
        label="Fino a"
        value={props.state.dateTo}
        onChange={(date: any) => {

          if (date) return props.dispatch({ type: "setDateTo", value: date as Date });
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </Grid>
    <Grid item sm={12} md={12} className="scroll" style={{ padding: 2 }}>
      <Accordion>
        {/* <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="readings_all"
          id="panel1a-header"
        > */}
        {/* <Typography variant="h4">Tabella Letture</Typography> */}
        {/* </AccordionSummary> */}
        <AccordionDetails id="readings">
          <Grid xs={12} className="scroll">
            <Table aria-label="readings_all">
              <TableHead>
                <TableRow>
                  {!props.state.selectedImmobile && <TableCell align="right">
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"sub_condominium"} columnLabel={"Sottocondominio"} />
                  </TableCell>}
                  {!props.state.selectedImmobile && <TableCell align="right">
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"immobile"} columnLabel={"Immobile"} />
                  </TableCell>}
                  <TableCell align="right">
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"internal"} columnLabel={"Interno"} />
                  </TableCell>
                  <TableCell align="right">
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"floor"} columnLabel={"Piano"} />
                  </TableCell>
                  <TableCell align="right">
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"serial_number"} columnLabel={"Numero Di serie"} />
                  </TableCell>
                  {props.state.meterType == "RIPARTITORE" && <TableCell>
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"room"} columnLabel={"Stanza"} />
                  </TableCell>}
                  {props.state.meterType == "RIPARTITORE" && <TableCell>
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"k"} columnLabel={"K"} />
                  </TableCell>}
                  {(props.state.meterType == "AF" || props.state.meterType == "ACS") && <TableCell>
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"type"} columnLabel={"Tipologia"} />
                  </TableCell>}
                  <TableCell>
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"read_time"} columnLabel={"Data Lettura"} />
                  </TableCell>
                  <TableCell align="right">
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"value"} columnLabel={"Valore"} />
                  </TableCell>

                  <TableCell align="right">
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"measure_unit"} columnLabel={"Unità di misura"} />
                  </TableCell>


                </TableRow>
              </TableHead>
              <TableBody key={`${props.state.readingsSortedBy}${props.state.readingSortedAsc}`}>
                {props.readings.filter((it) => it.read_time).sort(dynamicSort(props.state.readingsSortedBy, props.state.readingSortedAsc)).map((c: any) => {
                  return (
                    <TableRow key={c.id}>
                      {!props.state.selectedImmobile && <TableCell align="right">{c.sub_condominium}</TableCell>}
                      {!props.state.selectedImmobile && <TableCell align="right">{c.immobile}</TableCell>}
                      <TableCell align="right">{c?.flat_number ?? "--"}</TableCell>
                      <TableCell align="right">{c.floor}</TableCell>
                      <TableCell align="right">{c.serial_number}</TableCell>
                      {props.state.meterType == "RIPARTITORE" && <TableCell align="right">{c.room}</TableCell>}
                      {props.state.meterType == "RIPARTITORE" && <TableCell align="right">{c.k ? parseFloat(c.k.toString()).toFixed(2) : "--"}</TableCell>}
                      {(props.state.meterType == "AF" || props.state.meterType == "ACS") && <TableCell align="right">{c.type}</TableCell>}
                      <TableCell component="th" scope="row">
                        {moment(c.read_time).format("DD/MM/yyyy")}
                      </TableCell>
                      <TableCell align="right">{props.state.meterType == "CALORIE" ? (parseFloat(c.value) / 1000).toLocaleString() : parseFloat(c.value).toLocaleString()} </TableCell>
                      <TableCell align="right">{props.state.selectedTab == 2 ? "kWh" : c.measure_unit}</TableCell>
                      {/* <TableCell align="right">{JSON.stringify(c)}</TableCell> */}

                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
        {props.state.selectedCondominium && JSON.stringify(props.state.selectedCondominium.managedBy) === userId() &&
          <a target="_blank" href={`${props.config.base_url}/api/v1/export-condominium-admin?condominium_id=${props.state.selectedCondominium.id!}&date=${convertDate(props.state.dateTo)}&token=${window.sessionStorage.getItem("token")}`}>
            Scarica Letture Condominio</a>}

        {props.state.selectedCondominium && JSON.stringify(props.state.selectedCondominium.managedBy) === userId() &&
          <a target="_blank" href={`${props.config.base_url}/api/v1/export-condominium-admin-consumptions?condominium_id=${props.state.selectedCondominium.id!}&date_smaller=${convertDate(props.state.dateTo)}&date_greater=${convertDate(props.state.dateFrom)}&token=${window.sessionStorage.getItem("token")}`}>
            Scarica Consumi Condominio</a>}
      </div>

    </Grid>
  </Grid>
}

function SortHeader(props: { state: HomePageState, dispatch: React.Dispatch<HomePageAction>, columnName: string, columnLabel: string }) {
  return <TableSortLabel
    active={props.state.readingsSortedBy === props.columnName}
    direction={props.state.readingSortedAsc ? "asc" : 'desc'}
    onClick={() => {
      if (props.state.readingsSortedBy === props.columnName) {
        props.dispatch({ type: "setreadingSortedAsc", value: !props.state.readingSortedAsc })
      } else {
        props.dispatch({ type: "setreadingsSortedBy", value: props.columnName })
      }
    }}
  >
    {props.columnLabel}

  </TableSortLabel>

}