import { Accordion, AccordionDetails, AccordionSummary, FormControl, Grid, InputLabel, MenuItem, Select, TableSortLabel, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useMemo } from 'react';
import { VictoryChart, VictoryTheme, VictoryAxis, VictoryBar, VictoryZoomContainer, VictoryTooltip, VictoryPie } from 'victory';
import { chartColors, chartTheme, meterColor } from '../../utlis/chartsStyle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { HomePageState, HomePageAction, dynamicSort } from './homePageViewModel';
import styled from 'styled-components';
import { userId } from '../../utlis/security';

const C = styled.div`
height: 32px;
width: 32px;
border-radius: 32px;
background-color: black;
`

export function CumulativeConsumptions(props: { config: any, state: HomePageState, dispatch: React.Dispatch<HomePageAction> }) {


  const state = props.state;

  const convertValue = (item: any) => {
    if (item.v1 != undefined) {
      return `Calorie ${convertValueHeat(item)}, Frigorie ${convertValueCooling(item)}`

    }
    const diff = item.final_reading_values.volume - item.initial_reading_values.volume
    if (diff > 0)
      return (diff / 1000).toLocaleString("it-IT", { maximumSignificantDigits: 3 })

    return diff
  }
  const convertValueHeat = (item: any) => {
    if (item["deltas"] && item["deltas"]["heat_energy"]) {
      return (item.deltas.heat_energy / 1000).toLocaleString("it-IT", { maximumSignificantDigits: 3 });;
    }
    return 0;
  }
  const convertValueCooling = (item: any) => {
    if (item["deltas"] && item["deltas"]["cooling_energy"]) {
      return (item.deltas.cooling_energy / 1000).toLocaleString("it-IT", { maximumSignificantDigits: 3 });
    }
    return 0;
  }
  const filteredCumulativeConsumptions = useMemo(() => {

    const immobiles = new Set(props.state.condominiums.flatMap((c: any) => c.children.flatMap((c: any) => c.children).map((it: any) => it.data.name.replace(/ +(?= )/g, '').toUpperCase())))


    return state.cumulativeConsumptions
      .filter((r) => {
        r.immobile = r.immobile ? r.immobile?.toUpperCase() : null
        if (state.meterType.includes("AF") || state.meterType.includes("ACS"))
          return r.meter_type.includes("AF") || r.meter_type.includes("ACS");

        if (state.meterType.includes("CALORIE") || state.meterType.includes("FRIGORIE"))
          return r.meter_type.includes("CALORIE") || r.meter_type.includes("FRIGORIE");

        return r.meter_type == "RIPARTITORE"
      }).filter((a: any) => immobiles.has(a.immobile))
  }, [state])

  const cumulativec = filteredCumulativeConsumptions.sort((c1, c2) => c2.value - c1.value)

  return <Grid xs={12}>
    <Accordion>
      {/* <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Ripartizione Consumi</Typography>
      </AccordionSummary> */}
      <AccordionDetails>
        <Grid container justify="center">
          {props.state.selectedImmobile && <Grid xs={12} item sm={4}>
            <Typography variant="h4">Ripartizione consumi {moment(props.state.dateFrom).format("DD/MM")} - {moment(props.state.dateTo).format("DD/MM")}</Typography>
            <VictoryPie

              theme={chartTheme(props.config)}
              data={filteredCumulativeConsumptions.map((c1: any) => {
                const c = JSON.parse(JSON.stringify(c1))
                c["room"] = c["room"] ?? c["meter_serial_number"]
                // c["label"] = `${c["room"] ?? c["meter_serial_number"]}\nConsumo: ${c.value.toFixed(2)}`
                return c;
              })}

              style={{
                data: {
                  fill: (c: any) => {
                    const t = c?.datum?.meter_type ?? "AF";
                    const dataGroupedByMeter = Object.values(c.data.reduce((acc: any, x: any) => { acc[x.meter_serial_number] = x.meter_type; return acc; }, {})).filter(tt => tt == t)
                    return meterColor(Number(c?.datum?.meter_id ?? 1), c?.datum?.meter_type ?? "", dataGroupedByMeter.length == 1);
                  }
                }
              }}
              labelComponent={<VictoryTooltip />}
              x={"room"}
              y={"value"}
            />
          </Grid>}
          <Grid item xs={12} sm={props.state.selectedImmobile ? 8 : 12} className="scroll">
            {props.state.selectedImmobile && <Typography style={{ marginBottom: 48 }} variant="h4">
              Consumi per meter {props.state.selectedImmobile ? `(immobile ${props?.state?.selectedImmobile?.data?.name})` : ""}
            </Typography>}
            <Table aria-label="consumptions" className="scroll">
              <TableHead>
                <TableRow>
                  {!props.state.selectedImmobile && <TableCell>
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"sub_condominium"} columnLabel={"Sottocondominio"} />
                  </TableCell>}
                  {!props.state.selectedImmobile && <TableCell>
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"immobile"} columnLabel={"Immobile"} />
                  </TableCell>}
                  {!props.state.selectedImmobile && <TableCell>
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"internal"} columnLabel={"Interno"} />
                  </TableCell>}
                  <TableCell>
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"floor"} columnLabel={"Piano"} />
                  </TableCell>
                  <TableCell>
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"meter_serial_number"} columnLabel={"Matricola"} />
                  </TableCell>
                  {props.state.meterType == "RIPARTITORE" && <TableCell align="right">
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"room"} columnLabel={"Stanza"} />
                  </TableCell>}
                  {props.state.meterType == "RIPARTITORE" && <TableCell align="right">
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"k"} columnLabel={"K"} />
                  </TableCell>}
                  {(props.state.meterType == "AF" || props.state.meterType == "ACS") && <TableCell align="right">
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"meter_type"} columnLabel={"Tipologia"} />
                  </TableCell>}
                  <TableCell>Data Iniziale</TableCell>
                  <TableCell>
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"time"} columnLabel={"Data Finale"} />
                  </TableCell>
                  {props.state.selectedImmobile && <TableCell>Colore</TableCell>}
                  <TableCell align="right">
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"value"} columnLabel={"Consumo"} />
                  </TableCell>
                  <TableCell align="right">
                    <SortHeader state={props.state} dispatch={props.dispatch} columnName={"measure_unit"} columnLabel={"Unità di misura"} />
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody key={`${props.state.consumptionsSortedBy}${props.state.consuptionsSortAsc}`}>
                {filteredCumulativeConsumptions.sort(dynamicSort(props.state.consumptionsSortedBy, props.state.consuptionsSortAsc)).map((c: any, i) => {
                  return (
                    <TableRow key={c.id}>
                      {!props.state.selectedImmobile && <TableCell>{c?.meter?.subCondominium}</TableCell>}
                      {!props.state.selectedImmobile && <TableCell>{c?.meter?.immobile}</TableCell>}
                      {!props.state.selectedImmobile && <TableCell>{c?.internal ?? "--"}</TableCell>}
                      <TableCell>{c?.floor ?? "--"}</TableCell>
                      <TableCell>{c?.meter_serial_number ?? "--"}</TableCell>
                      {props.state.meterType == "RIPARTITORE" && <TableCell align="right">{c?.meter?.meterHeatDivider?.room ?? "--"}</TableCell>}
                      {props.state.meterType == "RIPARTITORE" && <TableCell align="right">{(Math.round(((c?.k ?? 1) + Number.EPSILON) * 1000) / 1000)}</TableCell>}
                      {(props.state.meterType == "AF" || props.state.meterType == "ACS") && <TableCell align="right">{c.meter_type}</TableCell>}
                      <TableCell component="th" scope="row">
                        {moment(props.state.dateFrom).format("DD/MM/yyyy")}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {moment(c.time).format("DD/MM/yyyy")}
                      </TableCell>
                      {props.state.selectedImmobile && <TableCell align="right">
                        <C style={{ backgroundColor: meterColor(c.meter_id ?? 1, c.meter_type, filteredCumulativeConsumptions.filter((cc) => cc?.meter?.type === c.meter_type).length === 1) }}></C>
                      </TableCell>}
                      <TableCell align="right">
                        {props.state.selectedTab == 2 ? convertValue(c) : (Math.round((c.value) + Number.EPSILON))}
                        {/* {c.v1 != undefined
                          //  ? `Calorie ${convertValue(Math.round((c.v2 / 1000)))}, Frigorie ${convertValue(Math.round((c.v1 / 1000)))}`
                          ? `Calorie ${convertValueHeat(c)}, Frigorie ${convertValueCooling(c)}`
                          : (Math.round((c.value) + Number.EPSILON))} */}
                      </TableCell>
                      <TableCell align="right">
                        {c.v1 != undefined ? "kWh" : (props.state.selectedTab == 2 ? "kWh" : c.measure_unit)}
                      </TableCell>

                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

          </Grid>

        </Grid>
      </AccordionDetails>
    </Accordion>

  </Grid>

}

export function SortHeader(props: { state: HomePageState, dispatch: React.Dispatch<HomePageAction>, columnName: string, columnLabel: string }) {
  return <TableSortLabel
    active={props.state.consumptionsSortedBy === props.columnName}
    direction={props.state.consuptionsSortAsc ? "asc" : 'desc'}
    onClick={() => {
      if (props.state.consumptionsSortedBy === props.columnName) {
        props.dispatch({ type: "setconsuptionsSortAsc", value: !props.state.consuptionsSortAsc })
      } else {
        props.dispatch({ type: "setconsumptionsSortedBy", value: props.columnName })
      }
    }}
  >
    {props.columnLabel}

  </TableSortLabel>

}
