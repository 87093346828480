import classes from '*.module.css';
import { Grid, List, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, TextField, CircularProgress, Typography } from '@material-ui/core';
import React, { useMemo, useReducer } from 'react';
import { Condominium, CondominiumNode, Immobile, SubCondominium } from '../../models/condominium';
import { post } from '../../utlis/genericRepository';
import { HomePageAction, HomePageState } from '../homePage/homePageViewModel';

type Props = {
    condominiums: Condominium[],
    reloadApp: () => void,
    config: any
}

type ImmobilesPageState = {
    condominiums: Condominium[],
    loading: boolean,
    code: string
    error: string,
    success: boolean,
    formVisible: boolean
}

type ImmobilesPageAction =
    | { type: "setLoading", value: boolean }
    | { type: "setFormVisible", value: boolean }
    | { type: "setError", value: string }
    | { type: "setCode", value: string }
    | { type: "setSuccess", value: boolean }

function immobilesReducer(state: ImmobilesPageState, action: ImmobilesPageAction): ImmobilesPageState {
    switch (action.type) {
        case "setSuccess": return { ...state, success: action.value }
        case "setError": return { ...state, error: action.value }
        case "setCode": return { ...state, code: action.value }
        case "setLoading": return { ...state, loading: action.value }
        case "setFormVisible": return { ...state, formVisible: action.value }
    }
}

export function ImmobilesPage(props: Props) {

    const [s, d] = useReducer(immobilesReducer, { condominiums: props.condominiums, loading: false, error: "", success: false, formVisible: false, code: "" });
    const immobiles: any[] = useMemo(() => {
        return props.condominiums.flatMap((c: Condominium) => c.children.flatMap((sc: CondominiumNode) => (sc.children as Immobile[]).map((imm) => [imm, c.data.cond_name])))
    }, [props.condominiums])

    return <Grid container>
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Condominio</TableCell>
                            <TableCell align="right">Immobile</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {immobiles.map(([immobile, condominium_name]: [Immobile, string]) => (
                            <TableRow key={immobile.data.name + (immobile.id ?? '')}>
                                <TableCell component="th" scope="row">
                                    {condominium_name}
                                </TableCell>
                                <TableCell align="right">{immobile.data.name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
        {!s.formVisible && <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={() => d({ type: "setFormVisible", value: true })}> Aggiungi Immobile </Button>
        </Grid>}


        {s.loading && <Grid style={{ marginTop: "3rem" }} container justify="center">
            <CircularProgress />
        </Grid>}

        {s.success && <Grid style={{ marginTop: "3rem" }} container justify="center">
            <Typography variant="h4">Congratulazioni! Immobile associato correttamente! <br /> Per favore esegui il logout e nuovamente il login per vedere in nuovo immobile!</Typography>
        </Grid>}

        {s.error != "" && <Grid style={{ marginTop: "3rem" }} container justify="center">
            <Typography variant="h4" color="error">
                Errore! Impossibile associare l'immobile
            </Typography>
        </Grid>}

        {
            s.formVisible && <>
                <Grid container justify="center" xs={12} style={{ marginTop: "3rem" }}>
                    <TextField
                        id="code"
                        label="Codice di invito"
                        style={{ width: "280px" }}
                        value={s.code}
                        onChange={(e) => { d({ type: "setCode", value: e.target.value }) }}
                    />
                </Grid>
                <Grid container justify="center" style={{ marginTop: "1rem" }} xs={12}>
                    <Button disabled={s.code == ""} color="primary" onClick={() => {
                        d({ type: "setLoading", value: true })
                        post({
                            code: s.code
                        }, `/api/v1/partner/${props.config.partner_id}/associate-immobile`).then((r) => {
                            if (typeof r == "string") {
                                d({ type: "setLoading", value: false });
                                d({ type: "setError", value: "Impossibile associare l'immobile" });
                            } else {
                                d({ type: "setLoading", value: false });
                                d({ type: "setSuccess", value: true });
                                d({ type: "setError", value: "" });
                                props.reloadApp()
                            }
                        });
                    }}> Aggiungi Immobile </Button>
                </Grid>
            </>
        }

    </Grid>
}


