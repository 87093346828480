
import { Container, Grid } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { ConfigContext } from "../App";
import styled from "styled-components";



const Im = styled.img`
  height: 300px;
  width: 300px;
  object-fit: scale-down;
  margin-top: 2rem;
`;

type LoginPageState = {
    userName: string;
    password: string;
    loading: boolean;
    error: string;
};
export function TermsAndConditions() {

    const config = useContext(ConfigContext);
    return <>
        <Container maxWidth="sm">
            <Grid container xs={12} justify="center">
                <Im src={config?.logo_url} />
            </Grid>

            <Grid
                container
                justify="center"
                style={{ marginTop: "2rem" }}
                xs={12}
            >
                <>
                    <h2>Termini e condizioni </h2>
                    <p>
                        Le diamo il benvenuto su Barbagli 1923!

                        I termini e le condizioni seguenti indicano regole e normative per l'utilizzo del sito di Barbagli 1923, all'indirizzo https://barbagli1923.mynetdomus.it.

                        Accedendo a questo sito, Lei accetta i termini e le condizioni seguenti. Le chiediamo di non continuare ad usare Barbagli 1923 nel caso in cui non fosse d'accordo con tutti i termini e le condizioni in questa pagina.
                    </p>
                    <h2>Cookie: </h2>
                    <p>
                        Il sito utilizza cookie per personalizzare l'esperienza online. Accedendo a Barbagli 1923, accetta l'uso dei cookie richiesti.

                        Un cookie è un file di testo che viene posizionato sull'hard disk da un server web. I cookie non possono essere usati per eseguire programmi o inviare virus al computer. I cookie vengono assegnati unicamente a Lei e possono essere letti solo dal server web nel dominio che ha emesso il cookie.

                        Potremmo usare i cookie per raccogliere, archiviare e tracciare informazioni per finalità statistiche e di marketing al fine di gestire il nostro sito web. Le consentiamo di accettare o rifiutare i cookie opzionali. Sono presenti dei cookie necessari indispensabili per il funzionamento del sito. Tali cookie non richiedono il consenso dato che sono attivi in ogni caso. Le chiediamo di tenere a mente che l'accettazione dei cookie richiesti prevede anche l'accettazione dei cookie di terzi, che potrebbero provenire da servizi forniti da terzi nel caso in cui decida di usare tali servizi sul nostro sito. Ad esempio, una schermata video fornita da terzi e integrata sul nostro sito.
                    </p>
                    <h2>Licenza:</h2>
                    <p>
                        A meno che indicato altrimenti, Barbagli 1923 e/o gli affiliati con licenza detengono i diritti di proprietà intellettuale per tutto il materiale su https://barbagli1923.mynetdomus.it.

                        Tutti i diritti di proprietà intellettuale sono riservati. L’utente può accedervi tramite https://barbagli1923.mynetdomus.it per uso personale soggetto a restrizioni indicate nei termini e nelle condizioni seguenti.

                        Non è consentito:
                        <ul>
                            <li>Copiare o ripubblicare il materiale di Barbagli 1923</li>
                            <li>Vendere, noleggiare o fornire in sub-licenza il materiale di Barbagli 1923</li>
                            <li>Riprodurre, duplicare o copiare il materiale di Barbagli 1923</li>
                            <li>Ridistribuire contenuti di Barbagli 1923 </li>
                        </ul>
                    </p>

                    <h2>Collegamenti ipertestuali ai nostri contenuti:</h2>
                    <p>
                        Le organizzazioni seguenti potrebbero aggiungere collegamenti ipertestuali al nostro sito senza previa autorizzazione scritta:
                        <ul>
                            <li>Agenzie governative;</li>

                            <li>Motori di ricerca;</li>

                            <li>Testate giornalistiche;</li>

                            <li>Distributori di directory online potrebbero aggiungere un collegamento ipertestuale al nostro sito nello stesso modo fatto per altri siti web indicati;</li>

                            <li>                Attività accreditate di sistema eccetto organizzazioni non-profit, società di beneficenza e gruppi di raccolta fondi per attività di carità che non possono aggiungere un collegamento ipertestuale al nostro sito.</li>
                        </ul>
                    </p>
                    <p>
                        Queste organizzazioni potrebbero aggiungere un collegamento ipertestuale alla nostra homepage, alle pubblicazioni e alle informazioni di altri siti a patto che il collegamento: (a) non sia ingannevole in alcun modo; (b) non implichi falsamente sponsorizzazione, pubblicità o approvazione del terzo che ha aggiunto il collegamento e dei suoi prodotti e/o servizi; (c) sia adatto al contesto del sito del terzo che ha aggiunto il collegamento.

                        Potremmo considerare e approvare altre richieste di collegamento dai seguenti tipi di organizzazioni:
                        <ul>
                            <li>fonti di informazioni commerciali e/o per consumatori conosciute;</li>

                            <li>siti di community punto.com;</li>

                            <li>associazioni o altri gruppi che rappresentano attività di carità;</li>

                            <li>distributori di directory online;</li>

                            <li>portali internet;</li>

                            <li>agenzie di consulenza, legali e di revisione contabile;</li>

                            <li>istituti di istruzione e associazioni professionali.</li>
                        </ul>
                    </p>
                    <p>
                        Approveremo le richieste di collegamento da queste organizzazioni se: (a) il collegamento non ci porrà in una posizione sfavorevole per noi stessi o per le nostre attività registrate; (b) l'organizzazione non ha precedenti negativi con noi; (c) il vantaggio dato dalla visibilità del collegamento ipertestuale compensa l'assenza per Barbagli 1923; (d) il link è in un contesto di informazioni generali.
                    </p>
                    <p>
                        Queste organizzazioni potrebbero aggiungere un collegamento alla nostra homepage a patto che il collegamento: (a) non sia ingannevole in alcun modo; (b) non implichi falsamente sponsorizzazione, pubblicità o approvazione del terzo che ha aggiunto il collegamento o dei suoi prodotti e/o servizi; (c) sia adatto al contesto del sito del terzo che ha aggiunto il collegamento.
                    </p>
                    <p>
                        Nel caso in cui Lei rappresenti una delle organizzazioni sopra indicate e sia interessato ad aggiungere un collegamento al nostro sito, deve informarci inviando un'email a Barbagli 1923. Le chiediamo di includere nome, denominazione dell'organizzazione, informazioni di contatto e URL del sito, una lista di URL su cui intende aggiungere un collegamento al nostro sito e una lista di URL sul nostro sito che intende collegare. Le chiediamo di attendere 2-3 settimane per una risposta.
                    </p>
                    <p>
                        Le organizzazioni approvate possono aggiungere un collegamento ipertestuale al nostro sito come segue:
                        <ul>
                            <li>Usando il nostro nome societario;</li>

                            <li>Usando l'uniform resource locator collegato;</li>

                            <li>Usando qualsiasi altra descrizione del nostro sito rilevante per contesto e formato dei contenuti sul sito terzo.</li>
                        </ul>
                    </p>
                    <p>
                        Non è consentito uso di logo o immagini di Barbagli 1923 per collegamenti in caso di mancanza di un accordo di licenza commerciale.
                    </p>

                    <h2>Responsabilità sui contenuti:</h2>
                    <p>
                        Non possiamo essere ritenuti responsabili per i contenuti presenti sul Suo sito. Accetta di proteggerci e difenderci da qualsiasi azione legale proveniente dal Suo sito. Non devono essere aggiunti collegamenti sul Suo sito che potrebbero essere interpretati come calunniosi, osceni, criminali o che infrangono, violano o suggeriscono la violazione di diritti di terzi.
                    </p>
                    <h2>Diritti riservati:</h2>
                    <p>
                        Ci riserviamo il diritto di richiedere la rimozione di tutti i collegamenti o di un collegamento specifico al nostro sito. Deve approvare immediatamente la richiesta di rimozione dei collegamenti. Ci riserviamo il diritto di modificare termini e condizioni, oltre che politica di collegamento in qualsiasi momento. Continuando a usare il nostro sito, accetta di essere vincolato dai presenti termini e condizioni.
                    </p>
                    <h2>Rimozione di collegamenti dal nostro sito:</h2>
                    <p>
                        Nel caso in cui un collegamento sul nostro sito sia offensivo per qualsiasi motivo, può contattarci e informarci in qualsiasi momento. Considereremo le richieste di rimozione dei collegamenti, tuttavia non siamo obbligati a completare la rimozione o rispondere direttamente
                    </p>
                </>
            </Grid>
        </Container >
    </>
}