import { useEffect, useReducer } from "react";
import { Meter } from "../../models/meter";
import { fetchConsumptions, fetchCumulativeConsumptions, fetchReadings } from "../../utlis/appRepository";
import { fetchCondominiums, fetchCondominiumMeters } from "../../utlis/condominium_repository";
import { pruneConsumptions } from "../../utlis/pruneDates";
import { HomePageAction, HomePageState } from "./homePageViewModel";

export function useHomePageState(reducer: (state: HomePageState, action: HomePageAction) => HomePageState, initialState: HomePageState, refresh: number): [HomePageState, React.Dispatch<HomePageAction>] {
    const [state, dispatch] = useReducer(reducer, initialState);

    //  Loads meter of given type when other parameters are set
    useEffect(() => {
        if (!(state.selectedCondominium)) return;
        dispatch({ type: "setLoadingMeter", value: true })
        fetchCondominiumMeters(state.selectedCondominium.id!, state.selectedSubCondominium?.id, state.selectedImmobile?.id).then((r: any) => {
            if (typeof r === "string") {
                dispatch({ type: "setLoadingMeter", value: false })
                return dispatch({ type: "setError", value: "Non riesco a scaricare i meter associati" });
            }
            dispatch({ type: "setMeters", value: r })
            dispatch({ type: "setLoadingMeter", value: false })
            //dispatch({ type: "setMeter", value: undefined })

        })
    }, [state.selectedCondominium, state.selectedSubCondominium, state.selectedImmobile])


    // Changing tab changes meter type
    useEffect(() => {
        switch (state.selectedTab) {
            case 0: return dispatch({ type: "setconsumptionsMeterType", value: "AF" })
            case 1: return dispatch({ type: "setconsumptionsMeterType", value: "RIPARTITORE" })
            case 2: return dispatch({ type: "setconsumptionsMeterType", value: "CALORIE" })
            default: return dispatch({ type: "setconsumptionsMeterType", value: "" })
        }
    }, [
        state.selectedTab
    ])



    // Fetch condominiums, then select the first condominium, the first sub_condominium the first immobile
    useEffect(() => {
        if (!state.selectedCondominium) fetchCondominiums().then((r) => {
            if (typeof r === "string") return dispatch({ type: "setError", value: r });
            else {
                dispatch({ type: "setCondominiums", value: r });

                if (r.length > 0) {
                    if (r.length === 1) {
                        dispatch({ type: "setCondominium", value: r[0] });

                        if (r[0].children.length > 0) {
                            dispatch({ type: "setSubCondominium", value: r[0].children[0] as any })
                            if (r[0].children[0].children.length > 0) {
                                dispatch({ type: "setImmobile", value: r[0].children[0].children[0] as any })
                            }
                        }
                    } else {
                        dispatch({ type: "setCondominium", value: r[0] });

                        dispatch({ type: "setSubCondominium", value: undefined })
                        //         dispatch({ type: "setImmobile", value: undefined })
                        //         dispatch({ type: "setMeter", value: undefined })
                    }
                }
            }
        })
    }, [0, refresh])



    // select first items from list
    useEffect(() => {
        if (!state.selectedCondominium || state.selectedSubCondominium) return;
        const condominium = state.selectedCondominium
        if (condominium.children.length > 0) {
            dispatch({ type: "setSubCondominium", value: condominium.children[0] as any })
            if (condominium.children[0].children.length > 0) {
                dispatch({ type: "setImmobile", value: condominium.children[0].children[0] as any })
            }
        }
        dispatch({ type: "setMeter", value: undefined })
    }, [state.selectedCondominium])


    useEffect(() => {

        if (!state.selectedSubCondominium) {
            dispatch({ type: "setImmobile", value: undefined })
        }

        dispatch({ type: "setMeter", value: undefined })
        dispatch({ type: "setScale", value: "daily" })
    }, [state.selectedImmobile, state.selectedSubCondominium])


    // fetch cumulative consumptions
    useEffect(() => {
        if (!(state.selectedCondominium)) return;
        dispatch({ type: "setLoadingCumulativeConsumptions", value: true })
        fetchCumulativeConsumptions(
            state.dateFrom,
            state.dateTo,
            state.selectedCondominium.id!,
            state.selectedSubCondominium?.id,
            state.selectedImmobile?.id,
            state.meterType, state.meters
        ).then((r) => {
            dispatch({ type: "setLoadingCumulativeConsumptions", value: false })
            if (typeof r === "string") return;
            dispatch({ type: "setCumulativeConsumptions", value: r })
        })
    }, [state.selectedCondominium, state.selectedSubCondominium, state.meters, state.meterType, state.selectedImmobile, state.dateTo, state.dateFrom])

    // once selected meter or immobile fetch its consumptions
    useEffect(() => {
        if (!(state.selectedMeter || state.selectedCondominium) || state.loadingData || state.meters.length == 0) return;
        (async () => {
            //  With meter or immobile fetch their data


            dispatch({ type: "setLoadingData", value: true });
            const r = await fetchConsumptions(state.dateFrom, state.dateTo, state.selectedMeter?.attributes?.identifier ?? {
                condominium_id: state.selectedCondominium!.id!,
                sub_condominium_id: state.selectedSubCondominium?.node_id,
                immobile_id: state.selectedImmobile?.node_id
            }, state.meters)
            if (typeof r !== "string") {
                dispatch({ type: "setConsumptions", value: r });
            }
            dispatch({ type: "setLoadingData", value: false });

        })()
    }, [
        state.selectedMeter,
        state.dateTo,
        state.dateFrom,
        state.scale,
        state.meters,
        state.selectedCondominium,
        state.selectedSubCondominium,
        state.selectedImmobile,
        state.selectedTab
    ]);

    // once selected meter fetch its readings
    useEffect(() => {
        if (state.loadingReading || state.meters.length == 0) return;
        (async () => {

            dispatch({ type: "setLoadingReading", value: true });

            const r2 = await fetchReadings({
                to: state.dateTo,
                condominium_id: state.selectedCondominium!.id!,
                sub_condominium_id: state.selectedSubCondominium?.node_id,
                immobile_id: state.selectedImmobile?.node_id
            }, state.meters)

            if (typeof r2 !== "string") {
                dispatch({ type: "setReadings", value: r2 });
            }
            dispatch({ type: "setLoadingReading", value: false });
        })()
    }, [
        state.meters,
        state.dateTo,
        state.dateFrom,
        state.selectedCondominium,
        state.selectedSubCondominium,
        state.selectedImmobile,
        state.selectedTab,
        //state.loadingReading
    ]);
    // 

    return [state, dispatch]
}
