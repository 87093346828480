import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import './App.css';
import { useFetch } from "react-async"
import { CircularProgress, createMuiTheme, ThemeProvider, Typography } from '@material-ui/core';
import {
  Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import { NoMatch } from './pages/noMatch';
import { Login } from './pages/login';
import { createBrowserHistory } from 'history';
import { HomePage } from './pages/homePage/homePage';
import { SignUp } from './pages/signup';
import { TermsAndConditions } from './pages/termsAndConditions';

export let BASE_URL = "";
export const history = createBrowserHistory();

export const ConfigContext = React.createContext<any>({});

function App() {
  const headers = { Accept: "application/json" }
  const { data, error, isPending, run } = useFetch("config.json", { headers })

  if (!window.sessionStorage.getItem("token") && !history.location.pathname.includes("sign-up") && !history.location.pathname.includes("terms")) history.push("/login");

  const theme = useMemo(() => {
    if (data) BASE_URL = (data as any)?.base_url ?? "";
    return createMuiTheme(data as any);
  }, [data]);



  if (isPending) return <CircularProgress />
  if (error) return <pre>{JSON.stringify(error)}</pre>

  return (
    <ThemeProvider theme={theme}>
      <ConfigContext.Provider value={data}>
        <Router history={history}>
          <Switch>
            <Route path="/home">
              <HomePage />
            </Route>
            <Route path="/sign-up">
              <SignUp />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/terms">
              <TermsAndConditions />
            </Route>
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </Router>
      </ConfigContext.Provider>
    </ThemeProvider>
  );
}

export default App;
