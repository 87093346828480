import {
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ConfigContext } from "../App";
import { post } from "../utlis/genericRepository";
import { history } from "../App";

const Im = styled.img`
  height: 300px;
  width: 300px;
  object-fit: scale-down;
  margin-top: 2rem;
`;

type LoginPageState = {
  userName: string;
  password: string;
  loading: boolean;
  error: string;
};

export function Login() {
  const config = useContext(ConfigContext);
  const [state, setState] = useState<LoginPageState>({
    userName: "",
    password: "",
    loading: false,
    error: "",
  });
  if (state.loading)
    return (
      <Grid container justify="center" style={{ marginTop: "2rem" }} xs={12}>
        <CircularProgress />
      </Grid>
    );

  return (
    <>
      <Container maxWidth="sm">
        <Grid container>
          <Grid container xs={12} justify="center">
            <Im src={config?.logo_url} />
          </Grid>

          <Grid
            container
            justify="center"
            style={{ marginTop: "2rem" }}
            xs={12}
          >
            <TextField
              id="username"
              label="username"
              style={{ width: 280 }}
              value={state.userName}
              onChange={(e) => {
                setState({ ...state, userName: e.target.value });
              }}
            />
          </Grid>
          <Grid container justify="center" xs={12}>
            <TextField
              id="password"
              label="password"
              style={{ width: 280 }}
              value={state.password}
              onKeyDown={(e) => {
                if (e.key == "Enter") doLogin(state, setState, config);
              }}
              onChange={(e) => {
                setState({ ...state, password: e.target.value });
              }}
              type="password"
            />
          </Grid>
          {state.error != "" && (
            <Grid
              container
              justify="center"
              style={{ marginTop: "2rem" }}
              xs={12}
            >
              <Typography variant="h4" color="error">
                Credenziali Errate
              </Typography>
            </Grid>
          )}
          <Grid
            container
            justify="center"
            style={{ marginTop: "2rem" }}
            xs={12}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => doLogin(state, setState, config)}
            >
              Login
            </Button>
          </Grid>
          <Grid
            container
            justify="center"
            style={{ marginTop: "1rem" }}
            xs={12}
          >
            <Button onClick={() => history.push("/sign-up")}>Registrati</Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

async function doLogin(
  state: LoginPageState,
  setState: React.Dispatch<React.SetStateAction<LoginPageState>>,
  config: any
): Promise<string | true> {
  let ns = state;
  state = { ...state, loading: true };
  setState(state);

  const results = await post(
    { username: state.userName, password: state.password },
    `/api/v1/partner/${config.partner_id}/login`
  );

  if (typeof results == "string") {
    state = { ...state, loading: false, error: results };
    setState(state);
    return results;
  }

  const token = results.access_token;
  window.sessionStorage.setItem("token", token);
  state = { ...state, loading: false, error: "" };
  setState(state);
  history.push("/home");
  return true;
}
