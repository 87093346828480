import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { VictoryChart, VictoryAxis, VictoryBar, VictoryTooltip, VictoryGroup, VictoryLabel } from 'victory';
import { chartColors, chartTheme, meterColor } from '../../utlis/chartsStyle';
import { HomePageState } from './homePageViewModel';
import styled from 'styled-components';
import { pruneConsumptions } from '../../utlis/pruneDates';
import { Condominium } from '../../models/condominium';



export function ConsumptionsAll(props: { consumptions: any[], cumulativeConsumptions: any[], config: any, state: HomePageState }) {

  const filteredCs = useMemo(() => {

    const meterss = props.state.meters.reduce((acc: any, x) => {
      acc[x.attributes.id!] = x.attributes
      return acc;
    }, {});

    const c = pruneConsumptions(props.consumptions, props.state.scale, props.state).map((consumption) => ({
      ...consumption,
      //immobile: meterss[consumption.meter_id]  ? meterss[consumption.meter_id].immobile?.toUpperCase() : null, 
      label: `${consumption["serial-number"] ? `${consumption["serial-number"]}\n` : ""}
      ${moment(consumption["initial_reading_time"]).format("DD/MM")}-${moment(consumption["time"]).format("DD/MM")}\n${consumption["value"]?.toFixed(2)} ${consumption["measure_unit"] ?? "HCA"} ${consumption["measure_unit"] == "HCA" ? `(K: ${consumption["k"]})` : ""}${consumption.room ? `\n${consumption.room}` : ""}`
    }))

    const immobiles = new Set(props.state.condominiums.flatMap((c: Condominium) => c.children.flatMap((c: any) => c.children).map((it) => it.data.name.replace(/ +(?= )/g, '').toUpperCase())))

    return c.filter((a: any) => immobiles.has(a.immobile))

  }, [props.consumptions, props.state])

  const [cal, setCal] = useState<"cal" | "frig">("cal");



  const groupedCons = useMemo(() => {
    //console.log(filteredCs)
    const grouped_by_meter = filteredCs.reduce((acc, x) => {
      const cons = [...(acc[x.meter_id] ?? []), x]
      acc[x.meter_id] = cons.map((it) => {
        it["tf"] = moment(it.time).startOf('day').format("DD/MM");
        if (props.state.selectedTab == 2 && cal == "cal") it.value = it.v1;
        if (props.state.selectedTab == 2 && cal == "frig") it.value = it.v2;
        if (props.state.selectedTab == 2) {
          it["value"] = it["value"] ? it["value"] / 1000 : it["value"]
          it["label"] = `${it["serial-number"] ? `${it["serial-number"]}\n` : ""}
          ${moment(it["initial_reading_time"]).format("DD/MM")}-${moment(it["time"]).format("DD/MM")}\n${it["value"]?.toFixed(2)} ${it["measure_unit"] ?? "HCA"} ${it["measure_unit"] == "HCA" ? `(K: ${it["k"]})` : ""}${it.room ? `\n${it.room}` : ""}`
        }
        it["rv"] = it["value"]
        it["value"] = it["value"] ?? 0
        return it
      })
      return acc
    }, {})

    const r = Object.keys(grouped_by_meter).sort().reduce(
      (obj: any, key: any) => {
        obj[key] = grouped_by_meter[key];
        return obj;
      },
      {}
    );



    Object.keys(r).forEach(k => {
      const v = r[k];
      v.sort((a: any, b: any) => (new Date(a.time)).getTime() - (new Date(b.time)).getTime())
    })

    //console.log(r)
    return r

  }, [filteredCs, cal, props.state.selectedTab]);

  // @ts-ignore
  const yetAnotherFuckingGrouping = Object.values(groupedCons).flatMap((i) => i).reduce((acc: any, x: any) => {
    const key = x["tf"]
    acc[key] = [...(acc[key] ?? []), x]
    return acc;
  }, {});

  const C = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 32px;
  background-color: black;
  `

  const dates = useMemo(() => {
    // @ts-ignore
    const l = new Array(...(new Set(Object.values(groupedCons).flatMap((it: any) => it.flatMap((it: any) => it.time)))))
    l.sort((a, b) => (new Date(a)).getTime() - (new Date(b)).getTime())
    return l
    // .map((it) => (new Date(it)).getTime());
  }, [groupedCons])

  return <Grid container justify="center">
    <Grid xs={12}>
      <Typography variant="h2" style={{ marginTop: "3rem" }}>
        Consumi
      </Typography>
      {props.state.selectedTab == 2 && <Grid xs={12}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel id="sottocondominio">Seleziona tipologia</InputLabel>

          <Select

            labelId="sottocondominio"
            style={{ width: '100%' }}
            id="demo-simple-select"
            value={cal}
            onChange={(e: any) => {
              setCal(e.target.value)
            }}
          >
            <MenuItem key={"cal"} value={"cal"}>Calorie</MenuItem>
            <MenuItem key={"frig"} value={"frig"}>Frigorie</MenuItem>
          </Select>
        </FormControl>
      </Grid>}
    </Grid>
    {props.state.selectedImmobile != undefined && <Grid item xs={12} md={12} style={{ height: 550 }}>
      <VictoryChart
        height={500}
        key={cal}
        domainPadding={100}
        theme={chartTheme(props.config, Math.min(window.innerWidth, 980))}
      >

        <VictoryGroup
          height={300}
          offset={5}
          domainPadding={0}
          colorScale={chartColors}
        >

          {
            groupedCons && Object.keys(groupedCons).map((k) => <VictoryBar

              height={200}
              key={`${k}${cal}`}
              style={{
                data: {
                  fill: (c: any) => {
                    const t = c?.datum?.meter_type ?? "AF";
                    const dataGroupedByMeter = Object.values(c.data.reduce((acc: any, x: any) => { acc[x.meter_serial_number] = x.meter_type; return acc; }, {})).filter(tt => tt == t)
                    return meterColor(Number(c?.datum?.meter_id ?? 1), c?.datum?.meter_type ?? "", dataGroupedByMeter.length == 1);
                  }, width: groupedCons[k].length == 1 ? 12 : undefined
                }
              }}
              data={groupedCons[k]}
              // @ts-ignore
              labelComponent={<FakeTooltip wholeData={yetAnotherFuckingGrouping} />}

              // x={(x) => (new Date(x.time).getTime())}
              x={(x) => (x.tf)}
              y={(y) => y["value"]}
            />)
          }




        </VictoryGroup>
        <VictoryAxis

          style={{ tickLabels: { fontSize: 15, padding: 23, angle: dates.length > 15 ? (-90) : 0 } }}

          tickValues={[...dates].map((x) => moment(x).format("DD/MM"))} tickFormat={(x) => {
            return x
          }} />
        <VictoryAxis dependentAxis tickFormat={(x) => {

          return x?.toFixed(2)
        }} />

      </VictoryChart>
    </Grid>}

    { }

  </Grid>
}

class FakeTooltip extends VictoryTooltip {

  constructor(aaa: any, bbbb: any) {
    super(aaa)
  }

  render() {
    // @ts-ignore
    // console.log(this?.props)
    // @ts-ignore
    const sameColumnData = (this?.props?.wholeData[this?.props?.datum?.xName] ?? []).filter((it: any) => it.rv != null && it.rv != undefined)
    // @ts-ignore
    const sameColumnDataUnfiltered = (this?.props?.wholeData[this?.props?.datum?.xName] ?? [])

    // @ts-ignore
    const sameColumnDataZero = (this?.props?.wholeData[this?.props?.datum?.xName] ?? []).filter((it: any) => it.rv != null && it.rv != undefined && it.rv != 0)
    // @ts-ignore
    const isFirstItem = (sameColumnDataUnfiltered[0] ?? {}).meter_id == this?.props?.datum?.meter_id

    // @ts-ignore
    if (sameColumnData.length == 0 && isFirstItem) return <VictoryLabel  {...this.props} angle={-90} y={this.props.height - 100} text="Nessun Dato"></VictoryLabel>

    // @ts-ignore
    if (this?.props?.datum.rv == 0 && isFirstItem && sameColumnDataZero.length == 0) return <VictoryLabel {...this.props} angle={-90} y={this.props.height - 100} text="Consumo a 0"></VictoryLabel>

    // @ts-ignore
    return super.render();

  }


}
