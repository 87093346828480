import { CircularProgress, Container, FormControl, Grid, Card, CardContent, InputLabel, MenuItem, Select, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { Header } from '../../components/header';
import { SubCondominium, Immobile, } from '../../models/condominium';
import { Meter } from '../../models/meter';
import { fetchCondominium, fetchCondominiumMeters, fetchCondominiums } from '../../utlis/condominium_repository';
import {
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { fetchConsumptions, fetchReadings } from '../../utlis/appRepository';
import { Consumptions } from './consumptions';
import { ConfigContext } from '../../App';
import { defaultState, reducer } from './homePageViewModel';
import { MeterSelectionForm } from './meterSelectionForm';
import { useHomePageState } from './homePageState';
import { CumulativeConsumptions } from './cumulativeConsumptions';
import { AlarmPage } from '../alarmPage/alarmPage';
import { ImmobilesPage } from '../immobilesPage/immobiles';
import { ConsumptionsAll } from './consumptions_all';
import { ReadingsAll } from './readings_all';
import { prune, pruneChunk } from '../../utlis/dataMangement';
import { userId } from '../../utlis/security';

const dc = (a: any) => JSON.parse(JSON.stringify(a))

export function HomePage(props: {}) {

    const config = useContext(ConfigContext);
    const [refresh, setRefresh] = useState(1);
    const defaultStateValue = useMemo(defaultState, [])
    const [state, dispatch] = useHomePageState(reducer, defaultStateValue, refresh)

    // @ts-ignore
    window.state = state


    const filteredReadings = useMemo(() => {
        const meterss = state.meters.reduce((acc: any, x) => {
            acc[x.attributes.serialNumber!] = x.attributes
            return acc;
        }, {});

        const immobiles = new Set(state.condominiums.flatMap((c: any) => c.children.flatMap((c: any) => c.children).map((it: any) => it.data.name.replace(/ +(?= )/g, '').toUpperCase())))
        const readings = dc(state.readings).filter((r: any) => {

            if (state.meterType.includes("AF") || state.meterType.includes("ACS"))
                return r.type.includes("AF") || r.type.includes("ACS");

            if (state.meterType.includes("CALORIE") || state.meterType.includes("FRIGORIE"))
                return r.type.includes("CALORIE") || r.type.includes("FRIGORIE");

            return r.type == "RIPARTITORE"

        })
            .map((r: any) => ({ ...r, meter: meterss[r.serial_number], meter_id: meterss[r.serial_number]?.id }))
            .filter((r: any) => !state.selectedMeter || r.meter_id == state.selectedMeter!.attributes!.id!)
        const readings2 = readings.filter((r: any) => immobiles.has(r.immobile ? r.immobile?.toUpperCase() : null))

        return readings2
    },
        [state])

    const filteredConsumptions = useMemo(() => {
        return state.consumptions.filter((r) => {

            if (state.meterType.includes("AF") || state.meterType.includes("ACS"))
                return r.meter_type.includes("AF") || r.meter_type.includes("ACS");

            if (state.meterType.includes("CALORIE") || state.meterType.includes("FRIGORIE"))
                return r.meter_type.includes("CALORIE") || r.meter_type.includes("FRIGORIE");

            return r.meter_type == "RIPARTITORE"

            // return r.meter_type === state.meterType;
        })
    }, [state.consumptions, state.meterType, state.scale])
    const filteredCumulativeConsumptions = useMemo(() => {
        return state.cumulativeConsumptions
            .filter((r) => {
                if (state.meterType.includes("AF") || state.meterType.includes("ACS"))
                    return r.meter_type.includes("AF") || r.meter_type.includes("ACS");

                if (state.meterType.includes("CALORIE") || state.meterType.includes("FRIGORIE"))
                    return r.meter_type.includes("CALORIE") || r.meter_type.includes("FRIGORIE");

                return r.meter_type == "RIPARTITORE"
            })
    }, [state])

    const isLoadingSomething = useMemo(() => state.loadingData || state.loadingReading || state.loadingCumulativeConsumptions || state.loadingMeters, [
        state.loadingData, state.loadingReading, state.loadingCumulativeConsumptions, state.loadingMeters
    ])


    if (state.error != "") return <Grid container justify="center" style={{ marginTop: "2rem" }}>
        <Typography variant="h4" color="error">
            Errore nel caricamento dei dati
        </Typography>
    </Grid>

    if (!state.selectedCondominium) return <Grid container justify="center" style={{ marginTop: "2rem" }}>
        <Typography variant="h4" color="error">
            Caricamento
        </Typography>
    </Grid>


    return <>
        <Header></Header>
        <Container maxWidth="lg">
            <Grid container justify="center">
                <Tabs
                    scrollButtons={"on"}
                    value={state.selectedTab}
                    onChange={(_, i) => {
                        dispatch({ type: "setTab", value: i })
                        //                        dispatch({ type: "setSubCondominium", value: undefined })
                        //                        dispatch({ type: "setImmobile", value: undefined })
                        //                        dispatch({ type: "setMeter", value: undefined })
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="simple tabs example">
                    <Tab label="Contatori Idrici" />
                    <Tab label="Ripartitori" />
                    <Tab label="Conta Calorie" />
                    <Tab label="Allarmi" />
                    <Tab label="Immobili" />
                </Tabs>
            </Grid>

            {state.selectedTab < 3 && <>
                <Container maxWidth="md">
                    <MeterSelectionForm state={state} dispatch={dispatch} withMeter></MeterSelectionForm>
                </Container>
                <Grid container justify="center" >
                    {isLoadingSomething && <Grid style={{ marginTop: "3rem" }} container justify="center">
                        <CircularProgress />
                    </Grid>}

                    {!isLoadingSomething && !state.selectedMeter &&
                        <ConsumptionsAll consumptions={filteredConsumptions} cumulativeConsumptions={filteredCumulativeConsumptions} state={state} config={config}></ConsumptionsAll>
                    }

                    {!isLoadingSomething && state.selectedMeter &&
                        <Consumptions dispatch={dispatch} consumptions={filteredConsumptions} cumulativeConsumptions={filteredCumulativeConsumptions} state={state} config={config}></Consumptions>
                    }

                    {state.loadingCumulativeConsumptions && state.cumulativeConsumptions.length > 0 && state.selectedMeter && <Grid style={{ marginTop: "3rem" }} container justify="center">
                        <CircularProgress />
                    </Grid>}

                    {!isLoadingSomething &&
                        <CumulativeConsumptions
                            state={state}
                            dispatch={dispatch}
                            config={config}
                        />
                    }

                    {!isLoadingSomething &&
                        <ReadingsAll readings={filteredReadings} config={config} state={state} dispatch={dispatch} />
                    }
                </Grid>
            </>}


            {/* LE ALTRE TAB, da spostare nel loro componente */}

            {state.selectedTab == 3 && <AlarmPage state={state} config={config} dispatch={dispatch}></AlarmPage>}


            {(state.selectedTab == 4) && <ImmobilesPage condominiums={state.condominiums} config={config} reloadApp={() => { setRefresh(refresh + 1) }} />}
        </Container>
    </>



}

