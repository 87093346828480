import moment from "moment-timezone";
import { HomePageState } from "../pages/homePage/homePageViewModel";
import { dc, reduceConsumptions } from "./dataMangement";

type inteval = "daily" | "weekly" | "monthly";

export function pruneConsumptions(consumptions: any[], interval: inteval, state: HomePageState) {
  const clonedC = dc(consumptions)!;
  const dateSomething = clonedC.map((a) => ({ ...a, date: (new Date(a.time)).getTime() }));

  const meterss = state.meters.reduce((acc: any, x) => {
    acc[x.attributes.id!] = x.attributes
    return acc;
  }, {});

  const initialDate = state.dateFrom;
  const finalDate = state.dateTo;

  if (interval == "daily") {
    return dateSomething
  }


  if (interval == "weekly") {
    const m = moment;

    let id = moment(initialDate).utc().startOf('day');

    const dates = [];

    while (id < moment(finalDate)) {
      dates.push(id.toDate());
      id = id.utc().add(1, 'week')
    }

    //console.log(dates)


    const consumptiosByMeter = dateSomething.reduce((acc, x) => { acc[x.meter_id] = [...(acc[x.meter_id] || []), x]; return acc }, {})

    const r = dates.reduce((acc: any, x: any) => {

      const byMeter = Object.keys(consumptiosByMeter).map((key: any) => {
        const array = consumptiosByMeter[key]

        const meter = meterss[key];


        const m = moment;
        // debugger
        const initialCons = array.filter((a: any) => moment(a.initial_reading_time).isSame(moment(x), 'day'))
        const finalCons = array.filter((a: any) => moment(a.final_reading_time).isSame(moment(x).add(1, 'week'), 'day'))

        if (initialCons.length == 0 || finalCons.length == 0) {
          //console.log(x)
          const qualcosa = {
            initial_reading_time: x.toISOString(),
            final_reading_time: moment(x).utc().add(1, 'week').toDate().toISOString(),
            time: moment(x).utc().add(1, 'week').add(-1, 'day').toDate().toISOString(),
            values: {},
            value: null,
            initial_reading_values: null,
            final_reading_values: null,
            meter_id: meter.id,
            immobile: meter.immobile
          };
          // debugger
          return qualcosa

        }

        const [initialConsv] = initialCons;
        const [finalConsv] = finalCons;

        return { ...reduceConsumptions(initialConsv, finalConsv), time: (finalConsv.time) }

        return { initialCons, finalCons }
      }) // [{initialCOns, finaleCOns}]
      acc[x] = byMeter

      return acc
    }, {})

    return Object.values(r).flatMap(i => i)
  }

  // montly
  const m = moment;

  let id = moment(initialDate).utc().startOf('day');

  const dates = [];

  while (id < moment(finalDate)) {
    dates.push(id.toDate());
    id = id.add(1, 'month')
  }

  const consumptiosByMeter = dateSomething.reduce((acc, x) => { acc[x.meter_id] = [...(acc[x.meter_id] || []), x]; return acc }, {})

  const r = dates.reduce((acc: any, x: any) => {

    const byMeter = Object.keys(consumptiosByMeter).map((key: any) => {
      const array = consumptiosByMeter[key]
      //console.log(consumptiosByMeter[key])

      const meter = meterss[key];
      //console.log(meter)

      const m = moment;
      // debugger
      const initialCons = array.filter((a: any) => moment(a.initial_reading_time).isSame(moment(x), 'day'))
      const finalCons = array.filter((a: any) => moment(a.final_reading_time).isSame(moment(x).add(1, 'month'), 'day'))

      if (initialCons.length == 0 || finalCons.length == 0) return {
        initial_reading_time: x,
        final_reading_time: moment(x).utc().add(1, 'month').toDate().toISOString(),
        time: moment(x).utc().endOf('month').add(-1, 'day').toDate().toISOString(),
        values: {},
        value: null,
        initial_reading_values: null,
        final_reading_values: null,
        meter_id: meter.id,
        immobile: meter.immobile
      };

      const [initialConsv] = initialCons;
      const [finalConsv] = finalCons;

      return { ...reduceConsumptions(initialConsv, finalConsv), time: (finalConsv.time) }

      return { initialCons, finalCons }
    }) // [{initialCOns, finaleCOns}]
    acc[x] = byMeter

    return acc
  }, {})

  return Object.values(r).flatMap(i => i)
}


function mergeConsumptions(cs: any[], scale: inteval) {
  if (cs.length == 0) return null;
  if (cs.length == 1) return cs[0];
  const cso = dc(cs)!.sort((a, b) => a.date - b.date);
  const minimum = cso[0];
  const maximum = cso[cso.length - 1];

  // date end of week di quella settimana o mese
  return { ...reduceConsumptions(minimum, maximum), time: (maximum.time).toISOString() }
}


const groupByMeterId = (obj: any[]) => {
  return obj.reduce((acc, v) => {
    acc[v.meter_id] = [...(acc[v.meter_id] ?? []), v]
    return acc
  }, {})
}

var groupByTimePeriod = function (obj: any, timestamp: any, period: any) {
  var objPeriod = {};
  var oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
  for (var i = 0; i < obj.length; i++) {
    var d: any = new Date(obj[i][timestamp]);
    if (period == 'daily') {
      d = Math.floor(d.getTime() / oneDay);
    } else if (period == 'weekly') {
      d = Math.floor(d.getTime() / (oneDay * 7));
    } else if (period == 'monthly') {
      d = (d.getFullYear() - 1970) * 12 + d.getMonth();
    } else {
      //console.log('groupByTimePeriod: You have to set a period! day | week | month | year');
    }
    // define object key
    // @ts-ignore
    objPeriod[d] = objPeriod[d] || [];
    // @ts-ignore
    objPeriod[d].push(obj[i]);
  }
  return objPeriod;
};